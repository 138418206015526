/* font -------------------------------- */
@font-face {
  font-family: Centra;
  src: url('./assets/fonts/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/fonts/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/fonts/CentraNo2-Book.ttf');
  font-weight: 400;
}


/* -------------------------------- Default CSS --------------------------------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 100;
  overflow-x: hidden;
  position: relative;
  background-color: #282c34 !important;
  color: white !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}


/* -------------------------------- Navbar -------------------------------- */
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTf6dUSv_E8fDXeaPSmF7T7CEDnyQq_u1sGbDjps9XLG_OXsbWjV2U-ctwB8rY4D35vBv0&usqp=CAU");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
nav.navbar a.navbar-brand {
    width: 35%;
    color: #fff;
    display: inline-flex;
    align-items: center;
    letter-spacing: 0.8px;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}

span.name{
  padding-left: 15px;
}

/* socials buttons -------------------------------- */
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 50%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

/* Call to Action button -------------------------------- */
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
  border-radius: .5rem;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}


/* -------------------------------- Banner -------------------------------- */
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTf6dUSv_E8fDXeaPSmF7T7CEDnyQq_u1sGbDjps9XLG_OXsbWjV2U-ctwB8rY4D35vBv0&usqp=CAU');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* -------------------------------- name */
.banner .name {
  font-weight: 700;
  letter-spacing: 0.8px;
  background-color: #fff;
  font-size: 70px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: block;
  color: rgba(225,225,225, .1);
  background-image: url("https://images.vexels.com/media/users/3/100871/raw/7250e4dbff71d24fe549846e315278c3-abstract-wave-color-light-background-vector.jpg");
  background-repeat: repeat-x;
  /* background-position: bottom; */
  background-size: cover;
  -webkit-background-clip:text;
  animation: animate 80s linear infinite;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
}

@keyframes animate {
    0%{
      background-position: left 0px top 10px;
    }
    40%{
      background-position: left 800px top 10px;
  }
}

/* Banner text content ---------------------- */
.banner h1 {
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  text-align: center;
}
.banner h2 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 0px;
  display: block;
  text-align: center;
}
.banner p {
  color: #f4f4f4;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 100%;
}

#animated {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}


/* -------------------------------- Skills -------------------------------- */
.skill {
  padding: 80px 0px;
  position: relative;
  background-color: rgb(18, 18, 82);
  /* background-image: url("https://wallpaperaccess.com/full/1353023.jpg");
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat; */
  padding: 100px 0 100px 0;
  box-sizing: border-box;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  margin: 14px auto 30px auto;
}

/* skills tab navs --------------------- */
.skill .nav.nav-pills {
  width: 60%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 1);
}
.skill .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.skill .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius:80px;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 2vw;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.skill .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;   
}
.skill .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}

/* skill card -------------------------- */
.skills-collection-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 15vw;
  list-style: none;
  grid-gap: 10px;
}

 .skill-img img{
  object-fit: scale-down;
  width: 100%;
  height: 100%;
  transition: ease-in
}

#bouncing:hover {
  animation: bounce 350ms;
}
@keyframes bounce {
  
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-50px); }
    100% { transform: translateY(0); }
    
}


/* --------------------------------  Projects --------------------------------  */
.project {
  padding: 75px 0;
  position: relative;
  background-color: rgb(18, 18, 82);
  /* background-image: url("https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"); */
  /* background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; */
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  padding-bottom: 50px;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 25px;
  width: 100%;
  height: 90%;
}

/* hover background --------------------------------  */
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txt {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txt {
  top: 50%;
  opacity: 1;
}
.proj-txt h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txt h5 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txt span {
  font-style: italic;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.8px;
  padding-bottom: 50px;
  line-height: 1.1em;
}

/* -------------------------------- Footer -------------------------------- */
.footer {
 background-color: #5908b5;
 padding: 25px 50px 10px;
 text-align: center;
 align-items: center ;
}

.footer p{
  color:rgb(85, 224, 255);
  font-weight: 100;
  font-size: 12px;
  padding-top: 10px;
  text-align: center;
  align-items: center;
}
.footer .name {
  font-weight: 700;
  letter-spacing: 0.8px;
  background-color: #fff;
  font-size: 35px;
  margin-bottom: 10px;
  display: block;
  color: rgba(214, 11, 146, 0.1);
  background-image: url("https://images.vexels.com/media/users/3/100871/raw/7250e4dbff71d24fe549846e315278c3-abstract-wave-color-light-background-vector.jpg");
  background-repeat: repeat-x;
  background-position: center center;
  background-size: cover;
  -webkit-background-clip:text;
  animation: animate 80s linear infinite;
  text-align: left;
  text-transform: uppercase;
  font-weight: 900;
}

@keyframes animate {
    0%{
      background-position: left 0px top 10px;
    }
    40%{
      background-position: left 800px top 10px;
  }
}

